import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify, {
  theme: {
    primary: {
      base: '#222c3d',
      lighten1: '#35465c'
    },
    secondary: '#424242',
    background: '#FAFAFA',
    accent: '#46cec4',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    green: {
      base: '#399e5a',
      lighten1: '#31a657',
      lighten2: '#28af54',
      lighten3: '#20b751',
      darken1: '#41965d'
    },
    red: {
      base: colors.red.darken2,
      lighten1: colors.red.darken1,
      lighten2: colors.red.base,
      lighten3: colors.red.lighten1,
      lighten4: colors.red.lighten2,
      darken1: colors.red.darken3
    }
  },
  options: {
    customProperties: true
  },
  iconfont: 'mdi'
});
