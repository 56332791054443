import Signin from './Index';

export default [
  {
    path: '/signin/:authorizationRequestId',
    name: 'idpSigning',
    component: Signin,
    props: true
  },
  {
    path: '/signin',
    name: 'default',
    component: Signin,
    props: false
  },
  {
    path: '/',
    name: 'signin',
    component: Signin,
    props: false
  }
];
