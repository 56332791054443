<template>
  <v-app :style="bgc">
    <v-content>
      <RouterView/>
    </v-content>
  </v-app>
</template>

<script>
//import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'App',
  components: {},
  data: () => ({
    error: '',
    bgc: {
      backgroundColor: 'white'
    }
  }),
  methods: {},
  watch: {},
  mounted() {
    axios.get('/v1.1/metadata')
    .then((metadata)=>{
      if ( metadata.data && metadata.data.result ) {
        this.bgc.backgroundColor = metadata.data.result.backgroundColor
      }
    })
    if (this.$browserDetect.isIE) {
      this.error =
        'Internet Explorer is not supported. Please use Chrome, Firefox, Edge, or Safari.';
    }
  }
};
</script>
