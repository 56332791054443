<template>
  <v-container bg fill-height grid-list-md text-xs-center style="max-width: 550px">
    <v-layout row wrap align-center>
      <v-flex>
        <template style="width: 500px" color="rgb(255,0,0,0)">
          <img src="/v1.1/metadata/logo" style="max-width: 500px; padding-bottom: 25px" />
        </template>
        <v-card style="width: 500px">
          <v-card-text> Please check your email for a one-time token </v-card-text>
          <v-card-text>
            <v-text-field label="Token" @keyup.enter="submitToken" v-model="token"></v-text-field>
            <div v-show="error" style="color: red">{{ error }}</div>
            <div v-show="errorCode" style="color: red">{{ errorCode }}</div>
          </v-card-text>

          <v-card-actions>
            <v-layout row>
              <v-flex justify-center>
                <v-btn primary @click="submitToken"> Submit </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      token: '',
      error: false,
      errorCode: false
    };
  },
  computed: {},
  methods: {
    submitToken: function () {
      axios
        .post('/2fa/checkEmailToken/', { token: this.token })
        .then((result) => {
          if (result.data && result.data.result && result.data.result == 'found') {
            window.location = '/';
          } else {
            this.error = 'Could not validate token. Please try again.';
          }
        })
        .catch((err) => {
          this.error = 'Could not validate token. Please try again or contact your administrator.';
        });
    }
  },
  created() {
    axios
      .get('/2fa/generateEmailToken')
      .then((result) => {})
      .catch((err) => {
        this.error = 'Could not generate email token. Please contact your administrator.';
        let errorCode =
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.length == 4
            ? err.response.data.error
            : '4100';
        this.errorCode = 'Error Code ' + errorCode;
      });
  },
  watch: {}
};
</script>
