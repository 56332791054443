<template>
  <component :is="mfaMethod" v-if="mfaMethod"/>
</template>

<script>

import axios from 'axios'
import googleAuthenticator from './googleAuthenticator';
import emailToken from './emailToken';
import GoogleAuthenticator from './googleAuthenticator.vue';

export default {
  components: {emailToken, googleAuthenticator},
  data() {
    return {
      mfaMethod: ""
    };
  },
  computed: {},
  methods: {
  },
  created() {
    axios.get('/2fa/mfaMethod')
    .then((result) =>{
      console.log(result);
      if ( result.data && result.data.result ) {
        this.mfaMethod = result.data.result;
      } 
    })
  },
  watch: {}
};
</script>
