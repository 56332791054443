import Vue from 'vue';
import Vuex from 'vuex';

import signin from './modules/signin';

Vue.use(Vuex);

// Default Currency Filter

export default new Vuex.Store({
  modules: {
    // Global Stores
    signin
  },
  state: {},
  mutations: {},
  actions: {}
});
