<template>
  <v-container bg fill-height grid-list-md text-xs-center style="max-width: 550px">
      <v-layout row wrap align-center>
        <v-flex>
          <template style="width: 500px" color="rgb(255,0,0,0)">
              <img src="/v1.1/metadata/logo" style="max-width: 500px; padding-bottom: 25px"/>
          </template>
          <v-card style="width: 500px"> 
            <v-card-text>
              Please enter your MFA token from Google Authenticator
            </v-card-text>
            <v-card-text >
                  <v-text-field label="Token" v-model="token"></v-text-field>
                  <div v-show="error" style="color: red">{{error}}</div>
            </v-card-text>

            <v-card-actions>
                <v-layout row>
                  <v-flex justify-center>
                    <v-btn primary @click="checkToken">
                      Submit
                    </v-btn>
                  </v-flex>
                </v-layout>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>

import axios from 'axios'

export default {
  components: {},
  data() {
    return {
      token: "",
      error: false
    };
  },
  computed: {},
  methods: {
    checkToken: function() {
      axios.get('/2fa/CheckToken/' + this.token)
      .then((result)=>{
        if ( result.data && result.data.result) {
          window.location = '/';
        } else {
          this.error = 'Could not validate token. Please try again.';
        }
      })
      .catch((err)=>{
        this.error = true;
      })
    }
  },
  created() {},
  watch: {}
};
</script>
