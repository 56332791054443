<template>
  <v-container bg fill-height grid-list-md text-xs-center style="max-width: 550px">
      <v-layout row wrap align-center>
        <v-flex>
          <template style="width: 500px" color="rgb(255,0,0,0)">
              <img src="/v1.1/metadata/logo" style="max-width: 500px; padding-bottom: 25px"/>
          </template>
          <v-card style="width: 500px" v-if="!passwordHasBeenSet"> 
            <v-card-text v-if="givenName && surname" style="padding-bottom: 0px;">
              Welcome {{givenName}} {{surname}}
            </v-card-text>
            <v-card-text>
              Please set your password below.
            </v-card-text>
            <v-card-text >
                  <v-text-field type='password' label="Password" v-model="password"></v-text-field>
                  <v-text-field type='password' label="Repeat Password" v-model="repeatPassword"></v-text-field>
                  <div :class="{rulePassed: rules.lowerCase, ruleFailed: !rules.lowerCase}">Password has at least 1 lower case letter</div>
                  <div :class="{rulePassed: rules.upperCase, ruleFailed: !rules.upperCase}">Password has at least 1 upper case letter</div>
                  <div :class="{rulePassed: rules.number, ruleFailed: !rules.number}">Password has at least 1 number</div>
                  <div :class="{rulePassed: rules.length, ruleFailed: !rules.length}">Password has at least 8 characters</div>
                  <div :class="{rulePassed: passwordsMatch, ruleFailed: !passwordsMatch}">Passwords match</div>
                  <div v-show="error" style="color: red">{{error}}</div>
            </v-card-text>

            <v-card-actions>
                <v-layout row>
                  <v-flex justify-center>
                    <v-btn primary @click="submit" :disabled="!rulesPass" v-if="!passwordHasBeenSet">
                      Verify Account
                    </v-btn>
                  </v-flex>
                </v-layout>
            </v-card-actions>
          </v-card>
          <v-card style="width: 500px" v-if="passwordHasBeenSet"> 
            <v-card-text>
              Success! You can now log in to your account. Please click the button below to continue.
            </v-card-text>
            <v-card-actions>
                <v-layout row>
                  <v-flex justify-center>
                    <v-btn primary @click="goToSignin">
                      Continue to Signin
                    </v-btn>
                  </v-flex>
                </v-layout>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>

import axios from 'axios'

export default {
  components: {},
  data() {
    return {
      givenName: "",
      surname: "",
      password: "",
      repeatPassword: "",
      error: false,
      rules: {
        lowerCase: false,
        upperCase: false,
        number: false,
        length: false
      },
      passwordHasBeenSet: false
    };
  },
  computed: {
    passwordsMatch: function() {
      return this.password == this.repeatPassword && this.password != '';
    },
    rulesPass: function() {
      console.log(this.rules.lowerCase);
      console.log(this.rules.upperCase);
      console.log(this.rules.number);
      console.log(this.rules.length);
      console.log(this.passwordsMatch);
      return this.rules.lowerCase && this.rules.upperCase && this.rules.number && this.rules.length && this.passwordsMatch;
    }
  },
  methods: {
    submit: function() {
      if ( this.password != this.repeatPassword ) {
        this.error = 'Passwords do not match';
        return;
      }

      const token = this.$route.query.token;

      axios.post('/verify/onetimelogin', {password: this.password, token})
      .then((result)=>{
        this.passwordHasBeenSet = true;
      })
      .catch((err)=>{
        this.error = true;
      })
    },
    goToSignin: function() {
      window.location = '/signin';
    }
  },
  created() {
    const token = this.$route.query.token;

    axios.get('/verify/onetimelogin/' + token)
    .then((result)=>{
      console.log(result);
      this.givenName = result.data ? result.data.givenName : '' || '';
      this.surname = result.data ? result.data.surname : '' || '';
    })
    .catch((err)=>{
      this.error = 'Could not validate token. Please contact your administrator'
    })
  },
  watch: {
    password: function(newPassword, old) {
      this.rules.lowerCase = false;
      this.rules.upperCase = false;
      this.rules.number = false;
      this.rules.length = false;

      let i = 0;
      while (i < newPassword.length) {
        let character = newPassword.charAt(i);
        if (character.charCodeAt(0) >= 48 && character.charCodeAt(0) <= 57) {
          this.rules.number = true;
        } else if (character == character.toLowerCase()) {
          this.rules.lowerCase = true;
        } else if (character == character.toUpperCase()) {
          this.rules.upperCase = true;
        } 
        i++;
      }

      if (newPassword.length >= 8) {
          this.rules.length = true;
        }
    }
  }
};
</script>


<style>
.rulePassed {
  color:green
}

.ruleFailed {
  color:#a18383
}
</style>