<template>
  <v-container bg fill-height grid-list-md text-xs-center style="max-width: 550px">
    <v-layout row wrap align-center>
      <v-flex>
        <template style="width: 500px" color="rgb(255,0,0,0)">
          <img src="/v1.1/metadata/logo" style="max-width: 500px; padding-bottom: 25px" />
        </template>
        <v-card style="width: 500px">
          <v-card-text>
            <v-text-field
              id="username"
              label="Email"
              v-model="email"
              @keyup.enter="submit"
            ></v-text-field>
            <v-text-field
              id="password"
              type="password"
              label="Password"
              v-model="password"
              @keyup.enter="submit"
            ></v-text-field>
            <div v-show="error" style="color: red">{{ error }}</div>
          </v-card-text>

          <v-card-actions>
            <v-layout row>
              <v-flex justify-center>
                <v-btn primary @click="submit" @keyup.enter="submit">
                  Sign In
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  props: ['authorizationRequestId'],
  data() {
    return {
      email: '',
      password: '',
      error: ''
    };
  },
  computed: {},
  methods: {
    submit: function() {
      const authorizationRequestId = this.$route.params.authorizationRequestId;
      const url = authorizationRequestId
        ? `/idp/interaction/${authorizationRequestId}/signin`
        : '/signin';
      axios
        .post(url, { username: this.email, password: this.password, uid: authorizationRequestId })
        .then((result) => {
          window.location = result.data;
        })
        .catch((err) => {
          this.error = err.response.data;
        });
    }
  },
  created() {},
  watch: {}
};
</script>
