import auth from './auth/Index';
import setup from './setup/Index';

export default [
  {
    path: '/mfa/',
    name: 'default',
    component: auth,
    props: false
  },
  {
    path: '/mfa/auth',
    name: 'mfaauth',
    component: auth,
    props: false
  },
  {
    path: '/',
    name: 'auth',
    component: auth,
    props: false
  },
  {
    path: '/mfa/setup',
    name: 'setup',
    component: setup,
    props: false
  }
];