<template>
  <v-container bg fill-height grid-list-md text-xs-center style="max-width: 550px">
    <v-layout row wrap align-center>
      <v-flex>
        <template style="width: 500px" color="rgb(255,0,0,0)">
          <img src="/v1.1/metadata/logo" style="max-width: 500px; padding-bottom: 25px" />
        </template>
        <v-card style="width: 500px">
          <v-card-text>
            Please scan the QR Code using Google Authenticator, enter the token, and then click
            Submit.
          </v-card-text>
          <v-card-text>
            <img :src="qrcode" />
            <v-text-field label="Token" v-model="token"></v-text-field>
            <div v-show="error" style="color: red">{{ error }}</div>
          </v-card-text>

          <v-card-actions>
            <v-layout row>
              <v-flex justify-center>
                <v-btn primary @click="submitToken">
                  Submit
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      qrcode: '',
      token: '',
      error: false
    };
  },
  computed: {},
  methods: {
    submitToken: function() {
      axios
        .get('/2fa/VerifyToken/' + this.token)
        .then((result) => {
          console.log(result);
          if (result.data && result.data.result) {
            window.location = '/';
          } else {
            this.error = result.data.error;
          }
        })
        .catch((err) => {
          console.log('error', err);
          this.error = err.error;
        });
    }
  },
  created() {
    axios
      .get('/2fa/GetQRCodeForNewUser')
      .then((result) => {
        if (result.data && result.data.error) {
          if (result.data.error == 'User is already set up to use MFA') {
            window.location = '/mfa/auth';
          }
        }
        this.qrcode = result.data.result;
        console.log(this.qrcode);
      })
      .catch((err) => {
        this.error = 'Could not create QR code';
      });
  },
  watch: {}
};
</script>
