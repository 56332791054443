import Vue from 'vue';
import App from './App.vue';

import router from '@base/plugins/vue-router';
import store from './store/index.js';

import '@base/plugins/vuetify';
import '@base/plugins/browserDetect';
import '@mdi/font/css/materialdesignicons.css';

import SigninRoutes from './views/signin/routes';
import MFARoutes from './views/mfa/routes';
import accountVerificationRoutes from './views/accountVerification/routes';
import resetPasswordRoutes from './views/resetPassword/routes';

router.addRoutes([...SigninRoutes, ...MFARoutes, ...accountVerificationRoutes, ...resetPasswordRoutes, { path: '/*', redirect: '/' }]);

const v = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
