<template>
  <v-container bg fill-height grid-list-md text-xs-center style="max-width: 550px">
    <v-layout row wrap align-center>
      <v-flex>
        <template style="width: 500px" color="rgb(255,0,0,0)">
          <img src="/v1.1/metadata/logo" style="max-width: 500px; padding-bottom: 25px" />
        </template>
        <v-card style="width: 500px" v-if="!passwordHasBeenReset">
          <v-card-text>
            Please enter your new password below.
          </v-card-text>
          <v-card-text>
            <v-text-field type="password" label="Password" v-model="password"></v-text-field>
            <v-text-field
              type="password"
              label="Repeat Password"
              v-model="passwordRepeat"
            ></v-text-field>
            <div :class="{ rulePassed: rules.lowerCase, ruleFailed: !rules.lowerCase }">
              Password has at least 1 lower case letter
            </div>
            <div :class="{ rulePassed: rules.upperCase, ruleFailed: !rules.upperCase }">
              Password has at least 1 upper case letter
            </div>
            <div :class="{ rulePassed: rules.number, ruleFailed: !rules.number }">
              Password has at least 1 number
            </div>
            <div :class="{ rulePassed: rules.length, ruleFailed: !rules.length }">
              Password has at least 8 characters
            </div>
            <div :class="{ rulePassed: passwordsMatch, ruleFailed: !passwordsMatch }">
              Passwords match
            </div>
            <div v-show="error" style="color: red">{{ error }}</div>
          </v-card-text>

          <v-card-actions>
            <v-layout row>
              <v-flex justify-center>
                <v-btn
                  primary
                  @click="submit"
                  :disabled="!passwordsMatch"
                  v-if="!passwordHasBeenReset"
                >
                  Reset
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
        <v-card style="width: 500px" v-if="passwordHasBeenReset">
          <v-card-text>
            Your password has been reset. Click the button below to continue to signin
          </v-card-text>
          <v-card-actions>
            <v-layout row>
              <v-flex justify-center>
                <v-btn primary @click="goToSignin">
                  Continue to Signin
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      email: '',
      password: '',
      passwordRepeat: '',
      passwordHasBeenReset: false,
      error: false,
      rules: {
        lowerCase: false,
        upperCase: false,
        number: false,
        length: false
      }
    };
  },
  computed: {
    passwordsMatch: function() {
      return this.password == this.passwordRepeat && this.password != '';
    },
    rulesPass: function() {
      console.log(this.rules.upperCase);
      console.log(this.rules.number);
      console.log(this.rules.length);
      console.log(this.passwordsMatch);
      return (
        this.rules.lowerCase &&
        this.rules.upperCase &&
        this.rules.number &&
        this.rules.length &&
        this.passwordsMatch
      );
    }
  },
  methods: {
    submit: function() {
      if (this.password != this.passwordRepeat) {
        this.error = 'Passwords do not match';
        return;
      }

      const token = this.$route.query.token;

      axios
        .post('/authentication/resetPassword', {
          token,
          email: this.email,
          password: this.password
        })
        .then((result) => {
          this.passwordHasBeenReset = true;
        })
        .catch((err) => {
          this.error = true;
        });
    },
    goToSignin: function() {
      window.location = '/signin';
    }
  },
  created() {
    const token = this.$route.query.token;
    console.log('token', token);
    if (!token) {
      this.error = 'Could not find reset password token';
    } else {
      axios
        .post('/authentication/verifyPasswordResetToken', { token: token })
        .then((result) => {
          /*this.email = result.data && result.data.result && result.data.result.email ? result.data.result.email : '';
        if ( !this.email ) {
          this.error = 'Could not validate password reset token. Request a new password reset email from your administrator.';
        }*/
        })
        .catch((error) => {
          this.error =
            'Could not validate password reset token. Request a new password reset email from your administrator.';
        });
    }
  },
  watch: {
    password: function(newPassword, old) {
      this.rules.lowerCase = false;
      this.rules.upperCase = false;
      this.rules.number = false;
      this.rules.length = false;

      let i = 0;
      while (i < newPassword.length) {
        let character = newPassword.charAt(i);
        if (character.charCodeAt(0) >= 48 && character.charCodeAt(0) <= 57) {
          this.rules.number = true;
        } else if (character == character.toLowerCase()) {
          this.rules.lowerCase = true;
        } else if (character == character.toUpperCase()) {
          this.rules.upperCase = true;
        }
        i++;
      }

      if (newPassword.length >= 8) {
        this.rules.length = true;
      }
    }
  }
};
</script>
